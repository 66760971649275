@import "../../../assets/scss/global.scss";

.content_wrapper {
  @include flex(column, none, center, 24px);
}

.login_wrapper {
  width: 100%;
  @include flex(column, none, none, 32px);
}

.inputs_wrapper {
  @include flex(column, none, none, 12px);
  width: 100%;
}

.info_wrapper {
  @include flex(column, none, none, 16px);
}

.recaptcha_wrapper {
  display: inline;
  transform: scale(1);
  transform-origin: 0 0;
}

.button_wrapper {
  width: 100%;
  @include flex(column, none, center, 16px);
}

.forgot_text {
  @include text-styles($text-medium, 500, center, $text-white);
}

.login_options_wrapper {
  @include flex(column, none, none, 16px);
  padding-top: 32px;
  border-top: 1px solid $border-color;
}

.login_options_heading {
  @include text-styles($text-medium, 400, center, $text-default);
}

.login_options_inner {
  @include flex(row, center, center, 16px);
}

.login_option {
  @include button;
  @include flex(row, center, center, 0);

  width: 100%;
  padding: 14px;
  background-color: $default;
}

.options_wrapper {
  @include flex(column, none, none, 16px);
}

.options_inner {
  @include flex(row, none, none, 16px);
}

.option {
  @include button;

  width: 100%;
  padding: 28px 0;
  background-color: $default;
}

.options_text {
  @include text-styles($text-medium, 400, left, $text-default);
}

.back_button {
  @include flex(row, center, center, 0);
  @include button;

  width: 56px;
  height: 56px;
  border-radius: 50%;
  color: $text-white;
  background-color: $default-dark;
}
