$border-radius: 8px;
$border-radius-small: 4px;
$border-radius-large: 12px;

$text-heading: 24px;
$text-large: 19px;
$text-semilarge: 18px;
$text-common-xlarge: 16px;
$text-common-large: 15px;
$text-common: 14px;
$text-medium: 13px;
$text-semimedium: 12px;
$text-small: 11px;
$text-semismall: 10px;
