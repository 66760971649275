@import "../../assets/scss/global.scss";

.profile_photo {
  @include flex(column, center, center, 8px);

  position: relative;
  width: 128px;
  height: 128px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 50%;
  border: 5px solid $body-background;

  &:hover > .photo_button {
    visibility: visible;
  }
}

.photo_button {
  @include flex(row, center, center, 0);
  @include button;

  visibility: hidden;
  width: 100%;
  height: 100%;
  opacity: 75%;
  color: $text-white;
  border-radius: 50%;
  background-color: $default;
}

.edit_button {
  @include flex(row, center, center, 0);
  @include button;

  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  opacity: 1;
  border-radius: $border-radius-large;
  color: $text-white;
  background-color: $primary;
}
