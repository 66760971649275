@import "../../assets/scss/global.scss";

.post_header {
  @include flex(row, space-between, center, 10px);

  padding: 14px;
  border-bottom: 1px solid $border-color;
}

.header_button {
  width: 24px;
  color: $text-white;
}

.button_icon {
  color: $text-default;
}

.header_text {
  @include text-styles($text-semilarge, 700, center, $text-white);
}

.comments_wrapper {
  @include flex(column, center, center, 20px);
  padding: 16px 0;
}

.comment_input_wrapper,
.comment {
  max-width: 665px;
  min-width: 450px;
  width: 100%;
}

.comments_list {
  width: 100%;
  list-style: none;
}

.comment_wrapper {
  @include flex(row, center, none, 0);
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid $border-color;
}
