@import "../../assets/scss/global.scss";

.news {
  @include flex(row, none, none, 16px);
  max-width: 712px;

  &:hover .game_title {
    visibility: visible;
  }
}

.top_news {
  @include flex(column, none, none, 8px);
  width: 50%;
}

.news_image_wrapper {
  position: relative;
  min-width: 275px;
  max-width: 275px;
  height: 170px;
  border-radius: $border-radius;
  border: 1px solid $border-color;
}

.top_news_image_wrapper {
  width: 100%;
  height: 220px;
  max-width: none;
  border-radius: $border-radius;
  border: 1px solid $border-color;
}

.news_image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border-radius: $border-radius;
}

.game_title {
  @include text-styles($text-common-large, 500, left, $text-white);
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px 10px;
  visibility: hidden;
  background-color: $primary;
  border-radius: $border-radius-large;
}

.news_content_wrapper {
  @include flex(column, space-between, none, 4px);
  height: 170px;
  width: 421px;
}

.secondary_text {
  @include text-styles($text-common-large, 500, left, $text-default);
}

.news_content_text {
  @include text-styles($text-common-large, 400, left, $text-default);
  width: 100%;
  height: 77px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.primary_text {
  @include text-styles($text-common-xlarge, 700, left, $text-white);
}

.news_link {
  @include text-styles($text-medium, 400, left, $text-white);
  width: fit-content;
  text-decoration: underline;
}
