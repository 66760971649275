@import "../../../assets/scss/global.scss";

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 24px 0;
  width: 300px;
  height: 100vh;
  background-color: $body-background;
  border-right: 1px solid $border-color;
}

.sidebar_inner {
  @include flex(column, space-between, center, 20px);

  padding: 0 32px;
  width: 100%;
  height: 100%;
}

.nav {
  @include flex(column, space-between, none, 24px);

  width: 100%;
}

.nav_list {
  @include flex(column, none, none, 5px);
}

.nav_link {
  width: 100%;
}

.nav_item {
  @include flex(row, flex-start, center, 8px);

  padding: 12px;
  color: $text-default;
  background-color: transparent;
  transition: 0.3s;

  &:hover {
    background-color: $primary2;
    color: $text-white;
  }

  &:hover > .nav_icon {
    fill: $text-white;
  }
}

.nav_icon {
  fill: $text-default;
}

.active_item {
  background-color: $primary2;
  color: $text-white;

  & > .nav_icon {
    fill: $text-white;
  }
}
