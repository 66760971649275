@import "../../assets/scss/global.scss";

.banner_image {
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:hover > .change_banner_wrapper {
    visibility: visible;
  }
}

.change_banner_wrapper {
  @include flex(row, center, center, 0);

  width: 100%;
  height: 100%;
  opacity: 75%;
  cursor: pointer;
  visibility: hidden;
  background-color: $default;
  border-top-left-radius: $border-radius-large;
  border-top-right-radius: $border-radius-large;
}

.change_banner {
  @include text-styles($text-common, 500, center, $text-white);
}

.edit_button {
  @include flex(row, space-between, center, 8px);
  @include button;

  position: absolute;
  right: 20px;
  bottom: 10px;
  padding: 8px;
  opacity: 1;
  color: $text-white;
  background-color: $primary;
  border-radius: $border-radius-large;

  & > p {
    @include text-styles($text-common, 400, none, $text-white);
  }
}
