@import "../../../../../../assets/scss/global.scss";

.dota_stats_wrapper {
  @include flex(column, none, none, 16px);
}

.overall_stats_body {
  @include flex(row, space-between, none, 16px);
  padding: 16px;
}

.stats_container {
  @include flex(row, center, center, 0);
  width: 25%;
  padding: 24px;
  border-radius: $border-radius;
  background-color: $outline;
}

.total_kda_wrapper {
  @include flex(row, center, center, 30px);
}

.kda_wrapper {
  @include flex(column, none, center, 8px);
}

.total_matches_wrapper {
  @include flex(column, none, center, 4px);
}

.total_matches_count {
  @include text-styles($text-heading, 600, left, $text-white);
}

.overall_stats_secondary_text {
  @include text-styles($text-common-xlarge, 400, left, $text-default);
}

.wins_loses_wrapper {
  @include flex(row, center, center, 24px);
}

.wins_loses_content {
  @include flex(column, none, center, 8px);
}

.wins_count {
  @include text-styles($text-heading, 600, left, $success);
}

.loses_count {
  @include text-styles($text-heading, 600, left, $error-x);
}

.matches_time_wraper {
  @include flex(column, none, none, 4px);
}

.matches_time {
  @include text-styles($text-large, 600, center, $text-white);
}

.dota_stats_body {
  @include flex(row, none, none, 16px);
}

.stats_sidebar {
  width: 30%;
  min-width: 470px;
}

.body_center {
  @include flex(column, none, none, 16px);
  width: 70%;
}

.match_details_wrapper {
  width: 100%;
  background-color: $default-dark;
  border-radius: $border-radius-large;
}

.match_details_header {
  @include flex(row, space-between, center, 10px);
  padding: 16px;
  border-bottom: 1px solid $border-color;
}

.stats_page_title {
  @include text-styles($text-common-xlarge, 600, left, $text-white);
}

.match_details_body {
  @include flex(column, none, none, 8px);
  padding: 16px;
}

.match_details_timeline {
  @include flex(row, space-between, center, 10px);
  padding: 8px 0;
}

.duration_wrapper {
  @include flex(row, none, center, 8px);
}

.primary_text {
  @include text-styles($text-common-xlarge, 400, left, $text-white);
}

.primary_text_large {
  @include text-styles($text-heading, 600, left, $text-white);
}

.secondary_text {
  @include text-styles($text-common, 400, left, $text-default);
}

.vertical_line {
  height: 20px;
  background-color: none;
  border: 1px solid $border-color;
}

.match_details_score_wrapper {
  @include flex(row, none, none, 0);

  height: 75px;
}

.match_details_dire {
  @include flex(column, space-between, none, 10px);
  position: relative;
  padding: 8px 14px;
  width: 50%;
  border-top-left-radius: $border-radius-large;
  border-bottom-left-radius: $border-radius-large;
  background-color: $border-color;
}

.match_details_radiant {
  @include flex(column, space-between, flex-end, 10px);
  position: relative;
  padding: 8px 14px;
  width: 50%;
  border-top-right-radius: $border-radius-large;
  border-bottom-right-radius: $border-radius-large;
  background-color: $outline;
}

.winner {
  @include text-styles($text-common, 500, center, $text-white);
  position: absolute;
  top: 8px;
  padding: 4px 8px;
  background-color: $primary;
  border-radius: $border-radius-large;
}

.dire_win {
  right: 14px;
}

.radiant_win {
  left: 14px;
}

.no_stats_title {
  @include text-styles($text-heading, 700, center, $text-white);

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
