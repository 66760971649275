@import "../../assets/scss/global.scss";

.modal_inner_wrapper {
  @include flex(column, none, none, 0);

  position: absolute;
  top: 45%;
  left: 50%;
  height: fit-content;
  border-radius: 12px;
  transform: translate(-50%, -50%);
  background-color: $body-background;
  border: none;
  outline: none;
}

.modal_header_wrapper {
  @include flex(row, space-between, center, 10px);

  padding: 16px 24px;
  border-bottom: 1px solid $border-color;
}

.modal_header {
  margin: 0;
  @include text-styles($text-semilarge, 700, left, $text-white);
}

.modal_footer {
  padding: 8px 24px;
}

.footer_text {
  @include text-styles($text-semimedium, 400, left, $text-default);
}
