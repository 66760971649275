@import "../../../assets/scss/global.scss";

.header {
  width: 100%;
}

.header_inner_wrapper {
  @include flex(column, center, center, 0);

  padding: 8px;
  min-width: 712px;
  width: 100%;
}

.header_input_wrapper {
  @include flex(column, none, none, 1px);

  width: 600px;
}

.header_input_wrapper:focus-within .search_results_wrapper {
  display: block;
}

.search_results_wrapper {
  display: none;
  position: absolute;
  top: 70px;
  width: 600px;
  z-index: 1000;
}

.header_icons_wrapper {
  max-width: 280px;
  min-width: 240px;
  width: 100%;
}

.header_icon {
  color: $text-white;
  cursor: pointer;
}
