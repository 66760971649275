@import "../../assets/scss/global.scss";

.ql-container {
  @include text-styles($text-common, 400, none, $text-white);

  height: 150px;
  border: none !important;
}

.ql-editor {
  height: 100%;
  &::-webkit-scrollbar-thumb {
    background-color: $background;
  }

  &::before {
    font-style: normal !important;
    color: $text-default !important;
  }
}

.ql-tooltip {
  z-index: 1000;
  background-color: $body-background !important;
  color: $text-white !important;
}

.ql-editing > input {
  background-color: $background !important;
  color: $text-white;
}
