@import "../../assets/scss/global.scss";

.follows_modal_body {
  @include flex(column, none, none, 0);
}

.tab_bar {
  @include flex(row, none, none, 0);
  border-bottom: 1px solid $border-color;
}

.tab_button {
  padding: 8px 24px;
  width: fit-content;
  border-radius: 0;
  font-weight: 400;
  color: $text-default;
  background-color: transparent;
}

.active_tab {
  color: $text-white;
  border-bottom: 1px solid $text-white;
}

.requests_tab_wrapper {
  position: relative;
}

.follow_requests {
  @include text-styles($text-semismall, 400, center, $text-white);

  position: absolute;
  top: 3px;
  right: 8px;
  color: $text-white;
  border-radius: 50%;
  background-color: $primary;
}

// ------------------------------- USER COMPONENT STYLES -------------------------------

.follows_wrapper {
  @include flex(column, none, none, 0);

  max-height: 300px;
  list-style: none;
  overflow: auto;
}

.follow_wrapper {
  @include flex(row, space-between, center, 10px);
  padding: 12px 24px;
}

.follow_info_wrapper {
  @include flex(row, none, center, 8px);
}

.profile_name {
  @include text-styles($text-common, 600, none, $text-white);
}

.follow_btn {
  @include text-styles($text-common, 400, center, $text-white);
  padding: 12px 24px;
  width: 120px;
}

.following_btn {
  color: $primary;
  border: 1px solid $primary;
  background-color: transparent;
}

.decline_btn,
.request_btn {
  background-color: transparent;
  border: 1px solid $text-white;
}

.remove_btn {
  color: $error-x;
  border: 1px solid $error-x;
  background-color: transparent;
}

.request_btns_wrapper {
  @include flex(row, none, none, 8px);
}

.accept_btn,
.decline_btn {
  @include flex(row, space-between, center, 8px);
  padding: 8px 24px 8px 16px;
}

.cancel_request_wrapper {
  @include flex(column, none, center, 24px);
  padding: 12px;
}

.cancel_request_title {
  @include text-styles($text-large, 500, center, $text-white);
}

.user_name_link {
  @include text-styles($text-large, 600, none, $primary);
  text-decoration: underline;
}

.cancel_request_buttons_wrapper {
  @include flex(row, none, none, 8px);
  width: 100%;
}

.cancel_button {
  border: 1px solid $text-white;
  background-color: transparent;
}

.confirm_button {
  color: $error-x;
  border: 1px solid $error-x;
  background-color: transparent;
}

// ------------------------------- TEAM COMPONENT STYLES -------------------------------

.team_wrapper {
  @include flex(row, none, none, 12px);
}

.team_info_wrapper {
  @include flex(column, none, none, 2px);
}

.members_count {
  @include text-styles($text-semimedium, 400, left, $text-default);
}

.game_logo_wrapper {
  width: 32px;
}

.game_logo {
  width: 100%;
  border-radius: $border-radius;
}
