@import "../../../../assets/scss/global.scss";

.game_header {
  @include flex(row, space-between, none, 15px);

  margin: 0 auto;
  padding: 24px 0;
  max-width: 665px;
  min-width: 450px;
  width: 100%;
  background-color: $body-background;
}

.game_image_wrapper {
  max-width: 445px;
  min-width: 445px;
  border: 1px solid $border-color;
  border-radius: $border-radius-large;
}

.game_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.game_description_wrapper {
  @include flex(column, none, center, 16px);

  width: 100%;
}

.game_logo_wrapper {
  width: 100px;
}

.game_info_wrapper {
  @include flex(column, none, none, 0);

  width: 100%;
  list-style: none;
}

.info_wrapper {
  @include flex(row, space-between, center, 5px);

  padding: 4px 0;
  border-bottom: 1px solid $border-color;

  &:last-of-type {
    border: none;
  }
}

.info_key {
  @include text-styles($text-semimedium, 400, left, $text-default);
}

.info_value {
  @include text-styles($text-common, 400, right, $text-white);
}

.down {
  gap: 12px;
}

.values_wrapper {
  @include flex(row, none, center, 5px);
}

.info_value_link {
  @include flex(row, flex-end, center, 2px);
  @include text-styles($text-common-large, 500, right, $text-white);

  text-decoration: underline;
}

.info_value_genre {
  @include text-styles($text-common, 400, left, $text-white);
}

.game_logo_block {
  width: 100%;
  height: 100%;
}

.loader {
  margin-top: 40%;
}
