@import "../../assets/scss/global.scss";

.auth {
	@include flex(row, center, center, 200px);
	height: 100vh;
}

.auth_inner {
	@include flex(row, space-between, center, 100px);

	flex-wrap: wrap;
	max-width: 900px;
	width: 100%;

	@media screen and (max-width: 806px) {
		justify-content: center;
		gap: 50px;
	}
}

.logo_wrapper {
	width: 300px;
	height: 80%;
	padding-top: 20px;
	padding-bottom: 24%;

	@media screen and (max-width: 752px) {
		@include flex(column, none, center, 10px);
		padding: 0;
	}
}

.logo {
	width: 272px;
}

.logo_slogan {
	@include text-styles($text-semilarge, 500, start, $text-white);
}
