@import "../../assets/scss/global.scss";

.post_wrapper {
  @include flex(row, center, none, 0);

  padding: 24px;
  width: 100%;
  border-bottom: 1px solid $border-color;
}

.post {
  @include flex(column, space-between, none, 16px);
  max-width: 665px;
  min-width: 450px;
  width: 100%;
}

.post_header {
  @include flex(row, space-between, center, 30px);
}

.post_info {
  @include flex(row, space-between, center, 10px);
}

.profile_info {
  @include flex(column, space-between, flex-start, 5px);
}

.profile_name {
  @include text-styles($text-common, 600, none, $text-white);
}

.post_time {
  @include text-styles($text-semismall, 600, none, $text-default);
}

.post_icon {
  color: $text-default;
  cursor: pointer;
}

.post_image_wrapper {
  @include flex(row, center, center, 0);
  width: 100%;
  height: 400px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.no_image {
  @include text-styles($text-semilarge, 500, center, $text-default);
}

.post_text_wrapper {
  @include flex(column, none, none, 4px);
}

.post_title {
  @include text-styles($text-semilarge, 700, none, $text-white);
}

.post_text {
  @include text-styles($text-common, 400, none, $text-disabled);

  & > span > p > a {
    color: $primary;
    text-decoration: underline;
  }
}

.post_footer {
  @include flex(row, space-between, center, 25px);
}

.buttons_wrapper,
.stats_wrapper {
  @include flex(row, none, center, 16px);
}

.post_button {
  @include flex(row, space-between, center, 8px);

  padding: 12px;
  width: fit-content;
  color: $text-default;
  background-color: $default;
  border-radius: $border-radius-large;
}

.stats_text {
  @include text-styles($text-semimedium, 400, none, $text-default);
  padding: 0;
}

.comment_wrapper {
  @include flex(row, none, flex-start, 10px);
}

.comment_input_wrapper {
  @include flex(column, none, flex-end, 15px);
  width: 100%;
}

.comment_button {
  width: fit-content;
}

.comments_wrapper {
  @include flex(column, none, flex-end, 20px);
}

.show_comments {
  @include text-styles($text-semimedium, 400, right, $text-disabled);
  @include button;

  width: fit-content;
  transition: 0.3s;
  cursor: pointer;
  background: none;

  &:hover {
    color: $text-white;
  }
}

.comments_list {
  @include flex(column, none, none, 5px);
  width: 100%;
}

.comment {
  padding: 20px 0;
  border-bottom: 1px solid $border-color;

  &:first-of-type {
    border-top: 1px solid $border-color;
  }

  &:last-of-type {
    border: none;
  }
}
