@mixin flex($direction, $content, $align, $gap) {
	display: flex;
	flex-direction: $direction;
	justify-content: $content;
	align-items: $align;
	gap: $gap;
}

@mixin text-styles($font-size, $font-weight, $text-align, $color) {
	font-size: $font-size;
	font-weight: $font-weight;
	text-align: $text-align;
	color: $color;
}

@mixin button {
	border: none;
	outline: none;
	border-radius: 8px;
	cursor: pointer;
}

@mixin box-shadow {
	box-shadow: 0px 25px 70px #0d0d12;
}
