@import "../../assets/scss/global.scss";

.button {
  @include button;
  @include flex(row, center, center, 0);
  @include text-styles($text-common, 600, center, $text-white);

  padding: 14px;
  width: 100%;
  background-color: $primary;
}

.disabled {
  color: $text-disabled;
  border-color: $disabled !important;
  background-color: $disabled !important;
}

.button_text {
  text-align: center;
}

.cancelButton {
  padding: 14px;
  width: 100%;
  background-color: transparent;
  border: white 1px solid;
}
