@import "../../assets/scss/global.scss";

.dropzone {
	@include flex(row, center, center, 0);
	height: 100%;
}

.dropzone_content {
	@include flex(column, none, center, 24px);
}

.dropzone_text_wrapper {
	@include flex(column, none, center, 12px);
}

.dropzone_title {
	@include text-styles($text-semilarge, 700, center, $text-white);
}

.dropzone_text {
	@include text-styles($text-common, 400, center, $text-white);
}

.dropzone_button {
	width: 98px;
	border: 1px solid $primary;
	background-color: transparent;
}
