@import "../../assets/scss/global.scss";

.layout {
  @include flex(row, center, flex-end, 0);

  width: calc(100vw - 5px);
  height: 100vh;
}

.header_wrapper {
  @include flex(row, space-between, center, 10px);

  padding: 0 8px;
  position: fixed;
  top: 0;
  left: 300px;
  z-index: 1;
  min-width: 712px;
  width: calc(100vw - 605px);
  background-color: $body-background;
  border-bottom: 1px solid $border-color;
}

.header_full {
  width: calc(100vw - 305px);
}

.header_button {
  @include flex(row, center, center, 0);
  @include button;

  padding: 8px;
  color: $text-white;

  &:first-child {
    background-color: transparent;
  }
  &:last-child {
    background-color: $default-dark;
  }
}

.horizontal_line {
  height: 1px;
  border: none;
  background-color: #313137;
}

.outlet {
  padding: 70px 300px 0;
  width: 100%;
  height: 100%;
}

.outlet_full {
  padding: 70px 0 0 300px;
}

.right_sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: $body-background;
  border-left: 1px solid $border-color;
}
