@import "../../assets/scss/global.scss";

.no_news {
  @include text-styles($text-heading, 600, center, $text-white);

  width: 95%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.news_load {
  @include text-styles($text-heading, 600, center, $text-white);
  margin-top: 150px;
  margin-left: 112px;
}

.dotted_loader {
  position: relative;
  text-align: center;
}
.dotted_loader::after,
.dotted_loader::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px #0075ff;
  border-style: dotted solid dotted solid;
  animation: turn-left 1.5s linear infinite;
}
.dotted_loader::before {
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  width: 150px;
  height: 150px;
  border-style: dotted dotted solid solid;
  animation: turn-right 1.5s linear infinite;
}

@keyframes turn-right {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes turn-left {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
