@import "../../../../assets/scss/global.scss";

.home_wrapper {
	width: 100%;
}

.no_posts {
	@include text-styles($text-heading, 600, center, $text-white);

	margin-top: 10%;
}
