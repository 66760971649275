@import "../../assets/scss/global.scss";

.profile_edit {
  @include flex(row, center, none, 16px);

  padding: 34px;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  @include flex(column, center, none, 16px);
  gap: 16px;

  width: 314px;
}

.inputs_wrapper {
  @include flex(column, justify-content, center, 10px);
  width: 100%;
}

.heading {
  @include text-styles($text-heading, 600, left, $text-white);

  margin-bottom: 8px;
}

.change_username_wrapper {
  @include flex(column, justify-content, center, 10px);
  width: 100%;
}

.change_username {
  @include text-styles($text-common, 400, left, $text-white);
  @include flex(row, none, center, 3px);
  @include button();

  background-color: transparent;
  text-decoration: underline;
  color: $text-white;
}

.change_password {
  @include button;
  @include text-styles($text-common, 400, left, $text-white);

  width: fit-content;
  text-decoration: underline;
  background-color: transparent;
}

.save_button {
  background-color: transparent;
  border: 1px solid $text-white;
}

.container_inner {
  background-color: $default-dark;
  border-radius: $border-radius-large;
  padding-bottom: 16px;
}

.secondary_select {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 8px;
}

.banner {
  border-top-left-radius: $border-radius-large;
  border-top-right-radius: $border-radius-large;
}

.account_info_wrapper {
  position: relative;
  padding: 45px 16px 6px;
  border-top: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.profile_photo {
  position: absolute;
  top: 0;
  z-index: 20;
  margin-top: -30px;
  border-color: $default-dark;
  border-width: 0px;
}

.profile_text {
  padding-left: 5px;
}

.username {
  @include text-styles($text-common, 700, left, $text-white);
  margin-bottom: 16px;
}

.secondary_heading {
  @include text-styles($text-common, 400, left, $text-default);
  margin-bottom: 8px;
}

.radios_container {
  @include flex(row, none, center, 16px);
}

.no_connections {
  @include text-styles($text-common, 400, left, $text-white);
}
