@import "../../assets/scss/global.scss";

.card {
	width: 212px;
	height: 304px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: $border-radius-large;
	position: relative
}
