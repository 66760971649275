@import "../../../assets/scss/global.scss";

.auth_block {
	@include flex(column, none, none, 16px);

	margin: 0 auto;
	width: 402px;
	color: $text-white;
}

.block {
	@include flex(column, none, none, 32px);

	padding: 24px;
	border-radius: 12px;
	background-color: $default-dark;
}

.block_title_wrapper {
	@include flex(column, none, none, 8px);
}

.block_title {
	@include text-styles($text-large, 600, none, $text-white);
}

.recaptcha_text {
	@include text-styles($text-small, 400, none, $text-default);
}

.recaptcha_link {
	color: $text-white;
}

.auth_option_text {
	@include text-styles($text-medium, 500, none, $text-default);
}

.auth_option_link {
	color: $text-white;
}
