@import "../../assets/scss/global.scss";

.no_stats_wrapper {
  @include flex(row, none, none, 24px);

  position: relative;
  padding: 12px 24px;
  width: fit-content;
}

.empty_box {
  width: 210px;
  height: 305px;
  border-radius: $border-radius;
  background: linear-gradient(
      180deg,
      rgba(23, 23, 28, 0) 0%,
      rgba(23, 23, 28, 0.9) 52.43%,
      rgba(23, 23, 28, 0.69) 100%
    ),
    var(--High-Surface-Color, #1d1d23);
}

.no_stats_text_wrapper {
  @include flex(column, none, center, 7px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.no_stats_title {
  @include text-styles($text-heading, 700, center, $text-white);
}

.no_stats_text {
  @include text-styles($text-common, 400, center, $text-default);
}

.here_link {
  color: $primary;
}

.no_stats_button {
  padding: 8px 16px;
  margin-top: 8px;
  width: fit-content;
}
