@import "../../assets/scss/global.scss";

.dropdown_wrapper {
  @include flex(row, none, center, 10px);

  position: relative;
  padding: 0 14px;
  background-color: $default;
  border-radius: $border-radius;
  cursor: pointer;
}

.image_wrapper {
  width: 34px;
}

.image {
  width: 100%;
  border-radius: $border-radius;
}

.dropdown {
  @include flex(row, flex-start, center, 5px);

  position: relative;
  width: 100%;
  height: 55px;
}

.dropdown_input {
  @include button;

  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  color: transparent;
  border-radius: $border-radius;
  background-color: transparent;
}

.dropdown_label {
  @include text-styles($text-common, 400, none, $text-default);

  position: absolute;
  top: 30%;
  left: 0;
  pointer-events: none;
  transition: 0.2s ease all;
}

.label_top {
  top: 8px;
  left: 0;
  font-size: 12px;
  opacity: 1;
}

.dropdown_value {
  @include text-styles($text-common, 400, left, $text-white);

  position: absolute;
  bottom: 10px;
}

.dropdown_icon {
  color: $text-default;
  position: absolute;
  right: 0;
}

.menu_wrapper {
  @include box-shadow;

  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  z-index: 10;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: $default;
  border-radius: $border-radius;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.menu {
  @include flex(column, none, none, 0);

  width: 100%;
  list-style: none;
}
