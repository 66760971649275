@import "../../../../../assets/scss/global.scss";

.weapons_wrapper {
  min-width: 760px;
  background-color: $default-dark;
  border-radius: $border-radius-large;
}

.weapons_header_wrapper {
  @include flex(row, none, center, 0);

  padding-bottom: 17px;
  border-bottom: 1px solid $border-color-dark;
}

.header_button {
  font-weight: 400;
  padding: 12px 24px;
  width: fit-content;
  transition: 0.3s;
  border-radius: 0;
  color: $text-default-light;
  background-color: transparent;
  border: 1px solid transparent;

  &:hover {
    color: $text-white;
    border-bottom: 1px solid $text-white;
  }
}

.active {
  color: $text-white;
  border-bottom: 1px solid $text-white;
}

.weapons_body_wrapper {
  @include flex(column, none, none, 16px);
  position: relative;
  min-height: 570px;
  padding: 16px;
}

.error_text {
  @include text-styles($text-common-xlarge, 400, center, $text-default);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cs2_table_wrapper {
  width: 100%;
  background-color: $outline;
  border-radius: $border-radius-large;
}

.table_headings_wrapper {
  @include flex(row, space-between, center, 20px);
  padding: 16px;
  border-bottom: 1px dashed $border-color-dark;
}

.table_heading {
  @include text-styles($text-common, 700, center, $text-white);

  width: 100%;

  &:first-of-type {
    text-align: left;
  }
  &:last-of-type {
    text-align: right;
  }
}

.table_body_wrapper {
  @include flex(column, none, none, 0);
  padding: 16px;
}

.table_row {
  @include flex(row, space-between, center, 20px);
  padding: 9px 0;
  border-bottom: 1px dashed $border-color-dark;
}

.table_cell {
  @include flex(row, center, center, 10px);
  @include text-styles($text-common, 400, center, $text-white);

  width: 100%;

  &:first-of-type {
    justify-content: flex-start;
  }
  &:last-of-type {
    justify-content: flex-end;
  }
}

.gun_image_wrapper {
  width: 60px;
}

.gun_image {
  max-width: 60px;
}

.pie_chart_wrapper {
  @include flex(row, center, center, 0);
  width: 23px;
}
