@import "../../assets/scss/global.scss";

.toolbar {
	width: fit-content;
	border: none !important;
	background-color: transparent;
}

.toolbar_button {
	padding: 0 !important;
	fill: $text-white !important;
}
