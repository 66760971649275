@import "../../../assets/scss/global.scss";

.stats_wrapper {
  position: relative;
  height: 100%;
}

.stats_header {
  @include flex(row, none, center, 16px);
  @include text-styles($text-common-xlarge, 700, left, $text-white);

  padding: 24px;
}

.back_icon {
  color: $text-default;
}

.stats_content {
  padding: 12px 24px;
}
