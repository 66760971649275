@import "../../assets/scss/global.scss";

.radio_wrapper {
  @include flex(row, none, center, 10px);
}

.radio_input {
  width: 12px;
  height: 12px;
  cursor: pointer;
  accent-color:#4957E9;
}

.label {
  @include text-styles($text-common, 400, left, $text-white);
  cursor: pointer;
}
