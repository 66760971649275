@import "../../../../assets/scss/global.scss";

.noification_wrapper {
  @include flex(row, space-between, flex-start, 12px);

  position: absolute;
  bottom: 0;
  left: calc(50% + 150px);
  transform: translate(-50%, -24px);
  padding: 12px 12px 12px 16px;
  width: 690px;
  border: 1px solid;
  background-color: $border-color;
  border-radius: $border-radius-large;
}

.text_wrapper {
  @include flex(column, none, none, 4px);
}

.info_heading {
  @include text-styles($text-semilarge, 600, left, $warning);
}

.info_text {
  @include text-styles($text-common-xlarge, 400, left, $text-default);
}

.error_text {
  @include text-styles($text-common-xlarge, 400, left, $warning);
}

.success_text {
  @include text-styles($text-common-xlarge, 600, left, $primary);
}

.close_icon {
  color: $text-white;
  cursor: pointer;
}
