@import "../../../../../../assets/scss/global.scss";

.last_match {
  @include flex(row, space-between, center, 10px);

  padding: 8px 16px;
  transition: 0.3s;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: $border-radius;
  &:hover {
    background-color: $border-color;
  }
}

.last_match_active {
  border-color: $primary;
  background-color: $border-color;
}

.last_match_text_wrapper {
  @include flex(column, none, none, 2px);
}

.hero_name {
  @include text-styles($text-common-xlarge, 600, left, $text-white);
}

.secondary_text {
  @include text-styles($text-common, 400, left, $text-default);
}

.lost_match {
  @include text-styles($text-common-xlarge, 400, left, $error-x);
}

.won_match {
  @include text-styles($text-common-xlarge, 400, left, $success);
}
