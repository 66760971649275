@import "../../assets/scss/global.scss";

.text_editor {
	width: 100%;
	background-color: $default;
	border-radius: $border-radius-large;
}

.toolbar_wrapper {
	@include flex(row, space-between, center, 20px);

	padding: 4px 8px;
	background-color: $background;
}

.top_toolbar,
.editor_top {
	padding: 0;
	border-top-left-radius: $border-radius-large;
	border-top-right-radius: $border-radius-large;
}

.bottom_toolbar,
.editor_bottom {
	border-bottom-left-radius: $border-radius-large;
	border-bottom-right-radius: $border-radius-large;
}

.textarea_button {
	padding: 8px 16px;
	width: fit-content;
}

// .textarea_wrapper {
// 	@include flex(column, none, none, 0);

// 	width: 100%;
// 	overflow: hidden;
// 	background-color: $default;
// 	border-radius: $border-radius-large;
// }

// .editor_wrapper {
// 	padding-bottom: 10px;
// 	border-radius: $border-radius-large;
// }

// .reverse {
// 	@include flex(column-reverse, none, none, 5px);

// 	padding-bottom: 0;
// }

// .toolbar {
// 	margin: 0;
// 	padding: 10px;
// 	width: 100%;
// 	border: none;
// 	background-color: $default-dark;
// 	border-top-left-radius: $border-radius-large;
// 	border-top-right-radius: $border-radius-large;
// }

// .down_toolbar {
// 	border-top-left-radius: 0;
// 	border-top-right-radius: 0;
// 	border-bottom-left-radius: $border-radius-large;
// 	border-bottom-right-radius: $border-radius-large;
// }

// .editor {
// 	@include text-styles($text-common, 400, left, $text-white);

// 	width: 100%;
// 	height: 150px;
// 	padding: 0 10px;
// 	overflow-y: scroll;
// }

// .toolbar_button {
// 	color: green;
// }

// .editor {
// 	position: relative;
// 	padding: 14px;
// 	height: 200px;
// 	color: $text-white;
// 	border: none;
// 	outline: none;

// 	&:focus ~ .editor_label {
// 		top: 7px;
// 		left: 14px;
// 		font-size: 10px;
// 	}
// }

// .editor_label {
// 	position: absolute;
// 	top: 14px;
// 	left: 14px;
// 	color: $text-default;
// }
