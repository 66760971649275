@import "../../../../assets/scss/global.scss";

.home_wrapper {
  width: 100%;
}

.no_posts {
  @include text-styles($text-heading, 600, center, $text-white);

  margin-top: 10%;
}

.loader {
  @include flex(row, center, center, none);
  width: 100%;
  height: 100%;
  margin-top: 50px;
  margin-left: -50px;
}
