@import "./utils/mixins.scss";
@import "./utils/colors.scss";
@import "./utils/variables.scss";

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: Inter, sans-serif;
  line-height: normal;
}

html {
  overflow-y: scroll;
}

body {
  background: $body-background;
}

a {
  color: $text-white;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: $border-radius;
  background: $default;
}

::-webkit-scrollbar-thumb:hover {
  background: $disabled;
}
