@import "../../../../../../assets/scss/global.scss";

.column {
  @include flex(column, none, none, 0);

  padding: 16px;
  width: 30%;
  background-color: $outline;
  border-radius: $border-radius-large;
}

.top_wrapper {
  @include flex(row, space-between, flex-start, 0);

  padding-bottom: 40px;
  border-bottom: 1px solid $border-color-dark;
}

.bottom_wrapper {
  @include flex(row, space-between, flex-end, 0);

  padding-top: 40px;
}

.column_inner {
  @include flex(column, space-between, none, 8px);
}

.inner_stats {
  @include flex(column, none, none, 4px);
}

.primary_text {
  @include text-styles($text-heading, 600, left, $text-white);
}

.secondary_text {
  @include text-styles($text-common-xlarge, 500, left, $text-default-light);
}

.percentage_text {
  @include text-styles($text-common-xlarge, 500, none, $text-default);
}
