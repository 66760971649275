@import "../../../../../../assets/scss/global.scss";

.stats_cell_container {
  border-radius: $border-radius-large;
  background-color: $default-dark;
  cursor: pointer;
}

.you {
  background-color: $border-color-dark;
}

.stats_cell_top {
  @include flex(row, space-between, center, 20px);
  padding: 16px;
}

.hero_info_wrapper {
  @include flex(row, none, none, 16px);
  height: 50px;
}

.hero_image {
  width: 50px;
  object-fit: cover;
  border-radius: $border-radius;
}

.text_info_wrapper {
  @include flex(column, center, none, 2px);
}

.username {
  @include text-styles($text-common-xlarge, 600, left, $text-white);
}

.hero_info_text_wrapper {
  @include flex(row, none, center, 8px);
}

.hero_level {
  @include text-styles($text-medium, 400, center, $text-white);
  padding: 4px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid $text-default;
}

.secondary_text {
  @include text-styles($text-medium, 400, left, $text-default-light);
}

.info_right_wrapper {
  @include flex(row, none, center, 16px);
}

.kda_info_wrapper {
  @include flex(column, none, none, 4px);
}

.kda_text_wrapper {
  @include flex(row, space-between, center, 4x);
  @include text-styles($text-medium, 400, left, $text-default);

  max-width: 60px;
  min-width: 50px;
  width: 100%;
}

.icon {
  color: $text-default;
}

.stats_cell_bottom {
  @include flex(column, none, none, 24px);
  padding: 16px;
  border-top: 1px solid $border-color;
}

.items_wrapper {
  @include flex(column, none, none, 12px);
}

.items_list {
  @include flex(row, flex-start, center, 8px);
  list-style: none;
}

.item {
  width: 32px;
  height: 32px;
  border-radius: $border-radius;
}

.item_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $border-radius;
}

.details_list {
  @include flex(row, space-around, center, 0);

  padding: 12px 0 8px;
  list-style: none;
}

.details_item {
  @include flex(column, none, center, 2px);

  padding: 0 8px;
}

.stats_text {
  @include text-styles($text-common, 600, left, $text-white);
}

.vertical_line {
  height: 37px;
  background-color: none;
  border: 1px solid $border-color;
}
