@import "../../../../../../../assets/scss/global.scss";

.overall_wrapper {
  min-width: 760px;
  background-color: $default-dark;
  border-radius: $border-radius-large;
}

.overall_header_wrapper {
  @include flex(row, none, center, 0);

  padding-bottom: 17px;
  border-bottom: 1px solid $border-color-dark;
}

.header_button {
  font-weight: 400;
  padding: 12px 24px;
  width: fit-content;
  transition: 0.3s;
  border-radius: 0;
  color: $text-default-light;
  background-color: transparent;
  border: 1px solid transparent;

  &:hover {
    color: $text-white;
    border-bottom: 1px solid $text-white;
  }
}

.active {
  color: $text-white;
  border-bottom: 1px solid $text-white;
}

.overall_body_wrapper {
  @include flex(column, none, none, 16px);
  position: relative;
  min-height: 570px;
  padding: 16px;
}

.error_text {
  @include text-styles($text-common, 400, center, $text-default);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.middle_column {
  padding: 16px;
  width: 40%;
  background-color: $outline;
  border-radius: $border-radius-large;
}

.inner_top_wrapper {
  @include flex(column, none, none, 16px);

  padding-bottom: 25px;
  border-bottom: 1px solid $border-color-dark;
}

.text_wrapper {
  @include flex(row, space-between, center, 20px);
}

.stats_wrapper {
  @include flex(column, none, none, 8px);
}

.stats_title {
  @include text-styles($text-common-xlarge, 500, none, $text-default-light);
}

.stats_text {
  @include text-styles($text-heading, 600, left, $text-white);
}

.stats_percentage {
  @include text-styles($text-common-xlarge, 500, left, $text-default-light);
  margin-left: 5px;
}

.bar_chart {
  @include flex(row, none, none, 0);
  height: 15px;
  width: 100%;
}

.chart_kills {
  height: 100%;
  background-color: $error-x;
  border-top-left-radius: $border-radius-small;
  border-bottom-left-radius: $border-radius-small;
}

.chart_deaths {
  height: 100%;
  background-color: $primary;
  border-top-right-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;
}

.inner_bottom_wrapper {
  @include flex(column, none, none, 8px);
  padding-top: 25px;
}

.headshots_wrapper {
  @include flex(row, space-between, flex-end, 8px);
}

.overall_inner_wrapper {
  @include flex(row, space-between, none, 16px);
}
