@import "../../../assets/scss/global.scss";

.game {
  @include flex(column, none, center, 24px);
  padding: 0 24px;
}

.game_body {
  margin: 0 auto;
  max-width: 665px;
  min-width: 450px;
  width: 100%;
}
