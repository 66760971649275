@import "../../assets/scss/global.scss";

.cancel_request_wrapper {
  @include flex(column, none, center, 24px);
  padding: 12px;
}

.cancel_request_title {
  @include text-styles($text-large, 500, center, $text-white);
}

.user_name_link {
  @include text-styles($text-large, 600, none, $primary);
  text-decoration: underline;
}

.cancel_request_buttons_wrapper {
  @include flex(row, none, none, 8px);
  width: 100%;
}

.cancel_button {
  border: 1px solid $text-white;
  background-color: transparent;
}

.confirm_button {
  color: $error-x;
  border: 1px solid $error-x;
  background-color: transparent;
}
