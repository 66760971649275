@import "../../assets/scss/global.scss";

.news_page_wrapper {
  @include flex(column, none, center, 70px);
  padding: 8px 16px;
}

.top_news_wrapper {
  @include flex(row, none, none, 24px);
  max-width: 1024px;
}

.top_news {
  @include flex(column, space-between, none, 8px);
  width: 50%;
}

.top_news_image_wrapper {
  width: 100%;
  height: 220px;
  border-radius: $border-radius;
  border: 1px solid $border-color;
}

.news_wrapper {
  @include flex(column, none, none, 24px);
  width: 100%;
  max-width: 1024px;
}

.news {
  @include flex(row, none, none, 16px);
  max-width: 712px;
}

.news_image_wrapper {
  min-width: 275px;
  max-width: 275px;
  height: 170px;
  border-radius: $border-radius;
  border: 1px solid $border-color;
}

.news_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $border-radius;
}

.news_content_wrapper {
  @include flex(column, space-between, none, 4px);
  height: 170px;
  width: 421px;
}

.secondary_text {
  @include text-styles($text-common-large, 500, left, $text-default);
}

.news_content_text {
  @include text-styles($text-common-large, 400, left, $text-default);
  width: 400px;
  height: 60px;
  overflow: hidden;
}

.primary_text {
  @include text-styles($text-common-xlarge, 700, left, $text-white);
  height: fit-content;
  width: fit-content;
}

.news_link {
  @include text-styles($text-medium, 400, left, $text-white);
  width: fit-content;
  text-decoration: underline;
}

.no_news {
  @include text-styles($text-heading, 600, center, $text-white);

  width: 95%;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
