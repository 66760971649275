@import "../../../../assets/scss/global.scss";

.news_page_wrapper {
  @include flex(column, none, none, 70px);
  padding: 8px 16px;
}

.top_news_wrapper {
  @include flex(row, none, none, 24px);
  max-width: 1024px;
}

.news_wrapper {
  @include flex(column, none, none, 24px);
}

.no_news {
  @include text-styles($text-heading, 600, center, $text-white);

  margin-top: 10%;
}

.loader {
  @include flex(row, center, center, none);
  width: 100%;
  height: 100%;
  margin-top: 50px;
  margin-left: -50px;
}
