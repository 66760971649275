@import "../../assets/scss/global.scss";

.like_button {
	@include flex(row, none, center, 8px);

	padding: 12px;
	width: 80px;
	height: 48px;
	color: $text-default;
	background-color: $default;
	border-radius: $border-radius-large;
}

.like_icon {
	color: $text-default;
}

.liked_button {
	color: $primary;
}

.liked_icon {
	color: $primary;
	animation: heart-burst 0.3s;
}

@keyframes heart-burst {
	0% {
		font-size: 16px;
	}
	100% {
		font-size: 24px;
	}
}
