@import "../../assets/scss/global.scss";

.comment_wrapper {
  @include flex(column, none, none, 5px);

  padding: 5px;
}

.comment_header {
  @include flex(row, none, center, 10px);
}

.profile_name {
  @include text-styles($text-semilarge, 600, left, $text-white);
}

.date {
  @include text-styles($text-small, 400, left, $text-default);
}

.comment_content {
  @include text-styles($text-common-large, 400, left, $text-disabled);

  margin-left: 50px;

  & > span > p > a {
    color: $primary;
    text-decoration: underline;
  }
}
