@import "../../assets/scss/global.scss";

.search_results_wrapper {
  width: 100%;
  border-radius: $border-radius;
  background-color: $default-dark;
}

.results_header {
  padding: 12px;
  width: 100%;
  border-bottom: 1px solid $border-color;
}

.header_text {
  @include text-styles($text-semimedium, 400, none, $text-default);
}

.results_wrapper {
  @include flex(column, none, none, 0);
  max-height: 400px;
  overflow: auto;
}

.result {
  @include flex(row, none, center, 8px);

  padding: 12px 18px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: $outline;
  }

  &:last-of-type {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.profile_info_wrapper {
  @include flex(column, none, none, 2px);
}

.profile_name {
  @include text-styles($text-semimedium, 600, none, $text-white);
}

.username {
  @include text-styles($text-semimedium, 400, none, $text-default);
}

.no_results_wrapper {
  @include flex(row, center, center, 0);

  padding: 12px;
}

.no_results_text {
  @include text-styles($text-semilarge, 600, none, $text-white);
}
