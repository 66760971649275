@import "../../../../assets/scss/global.scss";

.inputs_wrapper {
  @include flex(column, none, center, 16px);
  padding: 16px 24px;
}

.input {
  width: 100%;
}

.button_wrapper {
  padding: 16px 24px;
}
