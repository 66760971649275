@import "../../../../../../assets/scss/global.scss";

.last_matches_container {
  padding: 16px;
  background-color: $default-dark;
  border-radius: $border-radius-large;
}

.last_matches_header {
  padding-bottom: 8px;
  border-bottom: 1px solid $border-color;
}

.header_text {
  @include text-styles($text-common-large, 600, left, $text-white);
}

.last_matches_wrapper {
  @include flex(column, none, none, 8px);
  padding-top: 8px;
}
