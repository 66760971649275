@import "../../assets/scss/global.scss";

.menu_item {
	@include flex(row, none, center, 10px);

	padding: 10px 15px;
	width: 100%;
	transition: 0.3s;

	&:hover {
		background-color: $default-dark;
	}

	&:first-of-type {
		border-top-left-radius: $border-radius;
	}

	&:last-of-type {
		border-bottom-left-radius: $border-radius;
	}
}

.item_img {
	width: 24px;
	border-radius: $border-radius;
}

.item_text {
	@include text-styles($text-common, 400, left, $text-white);
}
