@import "../../../../assets/scss/global.scss";

.cs2_stats_wrapper {
  @include flex(column, none, none, 32px);
}

.tab_bar_wrapper {
  @include flex(row, space-between, center, 0);
}

.tab_bar {
  @include flex(row, none, center, 4px);
  padding: 4px;
  width: 345px;
  border: 1px solid $outline;
  border-radius: $border-radius-large;
}

.tab_button {
  font-weight: 400;
  transition: 0.3s;
  color: $text-default-light;
  background-color: transparent;
  border-radius: $border-radius-small;

  &:hover {
    color: $text-white;
    background-color: $primary;
  }
}

.active {
  color: $text-white;
  background-color: $primary;
}

.reload_wrapper {
  @include flex(row, none, center, 4px);
  cursor: pointer;
}

.reload_stats {
  @include text-styles($text-common-xlarge, 600, none, $primary);

  align-items: center;
}

.reload_icon {
  color: $primary;
}

.no_stats_wrapper {
  @include flex(column, none, center, 32px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.no_stats_text_wrapper {
  @include flex(column, none, center, 12px);
}

.no_stats_title {
  @include text-styles($text-heading, 700, center, $text-white);
}

.no_stats_text {
  @include text-styles($text-common, 400, center, $text-default);
}

.no_stats_button {
  padding: 8px 16px;
  width: fit-content;
}
