@import "../../../../../../../assets/scss/global.scss";

.last_match_wrapper {
  @include flex(row, space-between, none, 16px);
}

.results_wrapper {
  @include flex(column, space-between, none, 24px);
  padding: 16px;
  width: 30%;
  background-color: $outline;
  border-radius: $border-radius-large;
}

.match_result_wrapper {
  @include flex(row, none, none, 12px);
}

.stats_title {
  @include text-styles($text-common-xlarge, 500, none, $text-default-light);
}

.stats_text {
  @include text-styles($text-heading, 600, left, $text-white);
}

.stats_text_small {
  @include text-styles($text-semilarge, 600, left, $text-white);
}

.won {
  @include text-styles($text-semimedium, 600, none, $success);
  padding: 4px 8px;
  border-radius: $border-radius-large;
  background-color: rgba(79, 232, 113, 0.1);
}

.lost {
  @include text-styles($text-semimedium, 600, none, $error-x);
  padding: 4px 8px;
  border-radius: $border-radius-large;
  background-color: #e2322f1a;
}

.t_ct_wins_wrapper {
  @include flex(row, space-between, none, 20px);
}

.middle_column {
  padding: 16px;
  width: 40%;
  background-color: $outline;
  border-radius: $border-radius-large;
}

.inner_top_wrapper {
  @include flex(column, none, none, 16px);

  padding-bottom: 12px;
  border-bottom: 1px solid $border-color-dark;
}

.text_wrapper {
  @include flex(row, space-between, center, 20px);
}

.stats_wrapper {
  @include flex(column, none, none, 8px);
}

.stats_percentage {
  @include text-styles($text-common-xlarge, 500, left, $text-default-light);
  margin-left: 5px;
}

.bar_chart {
  @include flex(row, none, none, 0);
  height: 15px;
  width: 100%;
}

.chart_kills {
  height: 100%;
  background-color: $error-x;
  border-top-left-radius: $border-radius-small;
  border-bottom-left-radius: $border-radius-small;
}

.chart_deaths {
  height: 100%;
  background-color: $primary;
  border-top-right-radius: $border-radius-small;
  border-bottom-right-radius: $border-radius-small;
}

.inner_bottom_wrapper {
  @include flex(row, space-between, center, 16px);
  padding-top: 13px;
}

.fav_gun_wrapper {
  @include flex(column, none, none, 16px);
}

.chart_icon {
  @include flex(row, center, center, 0);
  padding: 8px;
  width: fit-content;
  border-radius: 50%;
  background-color: $disabled;
}

.tooltip_wrapper {
  width: 180px;
  padding: 8px 12px !important;
  background-color: $border-color !important;
  border-radius: $border-radius !important;
}

.tooltip_arrow {
  &::before {
    background-color: $border-color !important;
  }
}

.tooltip_list {
  @include flex(column, space-between, none, 8px);
  list-style: none;
}

.list_item {
  @include flex(row, space-between, center, 10px);
}

.tooltip_stats_title {
  @include text-styles($text-common, 400, left, $text-default);
}

.tooltip_stats_text {
  @include text-styles($text-common, 500, right, $text-white);
}

.average_damage_wrapper {
  @include flex(column, space-between, none, 16px);
  padding: 16px;
  width: 30%;
  background-color: $outline;
  border-radius: $border-radius-large;
}

.total_damage_wrapper {
  @include flex(row, space-between, center, 16px);

  padding-bottom: 8px;
  border-bottom: 1px dashed $border-color-dark;
}

.gun_image_wrapper {
  width: 170px;
}

.gun_image {
  max-width: 170px;
}
