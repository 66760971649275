@import "../../../../assets/scss/global.scss";

.container {
  @include flex(column, none, none, 8px);
  padding: 12px;
  max-width: 850px;
  width: 100%;
}

.topContainer {
  @include flex(row, none, center, 24px);
  width: 100%;
}

.centerContainer {
  @include flex(row, space-between, center, none);
  width: 100%;
}

.bottomContainer {
  @include flex(column, none, center, none);
  width: 100%;
}

.my_games {
  margin: 0 auto;
  padding: 24px 0;

  max-width: 665px;
  min-width: 450px;
  width: 100%;
}

.game {
  @include flex(row, none, center, 16px);

  padding: 4px 24px;
  cursor: pointer;

  &:hover {
    background-color: $default-dark;
  }
}

.game_number {
  @include text-styles($text-common, 400, left, $text-default);
}

.game_info_wrapper {
  @include flex(row, none, center, 10px);
}

.game_img {
  width: 32px;
}

.game_title {
  @include text-styles($text-common, 400, left, $text-white);
}

.no_stats_wrapper {
  @include flex(row, none, center, 24px);

  position: relative;
  width: fit-content;
}

.disclaimer_wrapper {
  @include flex(column, none, none, 8px);
  max-width: 600px;
}

.disclaimer_title_primary {
  @include text-styles($text-common-xlarge, 600, left, $text-white);
}

.disclaimer_title {
  @include text-styles($text-medium, 600, left, $text-default);
  text-transform: uppercase;
}

.disclaimer_text {
  @include text-styles($text-medium, 400, left, $text-default);
}
