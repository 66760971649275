@import "../../assets/scss/global.scss";

.input_wrapper {
  @include flex(column, none, none, 8px);
  position: relative;
}

.icons_input {
  @include flex(row, flex-end, center, 0);

  position: relative;
}

.left {
  justify-content: flex-start;
}

.search_icon {
  margin-right: -35px;
  width: 20px;
  z-index: 2;
  color: $text-default;
}

.input {
  @include text-styles($text-common, 400, start, $text-white);

  padding: 23px 35px 10px 14px;
  width: 100%;
  outline: none;
  background-color: $default;
  border: 1px solid transparent;
  border-radius: $border-radius;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }

  &:focus {
    border-color: $primary;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: all 60000s ease 0s;
  }

  &:focus ~ .left_icon_label {
    top: 8px;
    left: 40px;
    font-size: 12px;
    opacity: 1;
  }

  &:focus ~ .right_icon_label {
    top: 5px;
    left: 15px;
    font-size: 12px;
    opacity: 1;
  }
}

.input_label {
  @include text-styles($text-common, 400, none, $text-default);

  position: absolute;
  pointer-events: none;
  transition: 0.2s ease all;
}

.left_icon_label {
  top: 35%;
  left: 50px;
}

.right_icon_label {
  top: 33%;
  left: 20px;
}

.focused_left {
  top: 8px;
  left: 40px;
  font-size: 12px;
  opacity: 1;
}

.focused_right {
  top: 5px;
  left: 15px;
  font-size: 12px;
  opacity: 1;
}

.search_input {
  padding: 25px 14px 8px 40px;
  background-color: $default-dark;
}

.error_input {
  border: 1px solid $error;
  background-color: $default-error;
}

.input_button {
  @include flex(row, center, center, 0);
  @include button;

  height: fit-content;
  margin-left: -35px;
  background: none;
  color: $text-default;
  &:focus {
    color: $primary;
  }
}

.comment_button {
  color: $text-default;
  width: 24px;
  font-size: 24px;
  margin-left: -35px;
}

.errorMessage {
  @include text-styles($text-medium, 400, none, $error);
}

.fake_hash {
  @include text-styles($text-common, 400, start, $text-white);

  pointer-events: none;
  transition: 0.2s ease all;

  position: absolute;
  left: 16px;
  top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-default;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0 4px 4px 0;
}

.input_hash {
  padding-left: 32px;
}
