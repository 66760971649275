@import "../../../../assets/scss/global.scss";

.post_create_wrapper {
  @include flex(column, none, none, 0);
}

.inputs_wrapper {
  @include flex(column, none, none, 16px);

  padding: 16px 24px;
  border-bottom: 1px solid $border-color;
}

.dropdown {
  width: 55%;
}

.drag_area {
  position: relative;
  width: 100%;
  height: 300px;
  border: 1px dashed $border-color;
  border-radius: $border-radius-large;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.drag_area_image {
  width: 100%;
  height: 100%;
  opacity: 20%;
  object-fit: cover;
}

.dropzone_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttons_wrapper {
  @include flex(row, none, center, 10px);

  padding: 16px 24px;
}

.cancel_button {
  background-color: transparent;
  border: 1px solid $text-white;
}
