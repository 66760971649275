$primary: #4655f4;
$warning: #ffd54f;
$success: #4fe871;
$error-x: #e2322f;
$error: #c04d4d;
$primary2: #4755f4;

$default: #2c2c34;
$default-dark: #1d1d23;
$default-error: #292b3a;

$disabled: #373739;

$text-white: #efefef;
$text-default: #8c8d94;
$text-disabled: #b1b2be;
$text-default-light: #efefefb3;

$border-color: #27272d;
$border-color-dark: #313137;
$background: #14141a;
$body-background: #17171c;
$outline: #222228;
