@import "../../../../assets/scss/global.scss";

.modal_content_wrapper {
  @include flex(column, none, none, 32px);

  padding: 16px 24px;
  border-bottom: 1px solid $border-color;
}

.no_border {
  border: none;
}

.content_form_wrapper {
  @include flex(column, none, none, 17px);
}

.radios_wrapper {
  @include flex(row, none, center, 18px);
}

.inputs_wrapper {
  @include flex(column, none, center, 8px);
}

.summoner_input {
  width: 100%;
}

.server_dropdown {
  width: 100%;
}
