@import "../../assets/scss/global.scss";

.dashboard {
  @include flex(column, none, center, 0);

  min-width: 712px;
  width: 100%;
}

.dashboard_body {
  @include flex(column, none, center, 0);

  width: 100%;
}

.dashboard_inner_body {
  max-width: 850px;
  width: 100%;
}

.body_account_wrapper {
  @include flex(row, space-between, flex-end, 20px);

  padding: 24px;
  padding-bottom: 0;
  width: 100%;
}

.account_info_wrapper {
  @include flex(row, none, center, 12px);
}

.profile_photo {
  margin-top: -70px;
}

.account_info_inner_wrapper {
  @include flex(column, none, none, 8px);
}

.account_profile_name {
  @include text-styles($text-heading, 600, left, $text-white);
}

.accounts_connect {
  @include flex(row, none, center, 8px);
}

.connect_success {
  padding: 0;
  background-color: transparent !important;
}

.post_button {
  padding: 9px 16px;
  width: fit-content;
}

.following_button,
.requested_button {
  padding: 9px 16px;
  width: fit-content;
  border: 1px solid $text-white;
  background-color: transparent;
}

.body_follow_wrapper {
  @include flex(row, none, center, 12px);

  padding: 16px 24px;
}

.follow_text {
  @include text-styles($text-medium, 500, left, $text-default);
  @include button;
  background-color: transparent;
}

.subpages_wrapper {
  @include flex(column, none, center, 0);

  width: 100%;
}

.subpages_header_wrapper {
  @include flex(row, flex-start, center, 0);

  max-width: 850px;
  width: 100%;
  padding-bottom: 10px;
}

.subpage_link {
  width: fit-content;
}

.subpage_button {
  @include text-styles($text-common, 500, center, $text-default);

  padding: 12px 24px;
  width: fit-content;
  border-radius: 0;
  border: 2px solid transparent;
  background-color: transparent;
}

.active_subpage {
  color: $text-white;
  border-bottom-color: $text-white;
}

.subpage_content {
  @include flex(row, center, none, 0);
  position: relative;
  width: 100%;
  height: calc(100vh - 555px);
  border-top: 1px solid $default-dark;
}

.private_account_wrapper {
  @include flex(column, none, none, 7px);

  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.private_account_title {
  @include text-styles($text-common-xlarge, 700, center, $text-white);
}

.private_account_text {
  @include text-styles($text-common, 400, center, $text-white);
  width: 276px;
}
